<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">工区报表查询</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div style="margin: 0 auto;width: 95%;background-color: white;padding-top: 30px">
      <el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
        <el-tab-pane label="隧道工效" name="first" style="margin-left: 30px"><Backdgx></Backdgx></el-tab-pane>
        <el-tab-pane label="混凝土初支" name="second" style="margin-left: 30px"><BackFirstShort></BackFirstShort></el-tab-pane>
        <el-tab-pane label="沉降观测" name="third" style="margin-left: 30px"><Backsink></Backsink></el-tab-pane>
        <el-tab-pane label="二衬报表" name="four" style="margin-left: 30px"><Backlin></Backlin></el-tab-pane>
        <el-tab-pane label="仰拱报表" name="fir" style="margin-left: 30px"><Backinvert></Backinvert></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Backdgx from '@/views/procoll/back/backsdgx';
import BackFirstShort from "@/views/procoll/back/backfirstshort"
import Header from "@/views/header";
import Backsink from  "@/views/procoll/back/backsink";
import Backlin from "@/views/procoll/back/backlin";
import Backinvert from "@/views/procoll/back/backinvert";
export default {
  name: "back",
  components: {Backdgx, Header, BackFirstShort, Backsink, Backlin, Backinvert},
  data(){
    return{
      activeName: 'first'
    }
  },
  created() {
    if (!this.$route.params.key){
      this.$router.push("/manage")
    }
  },
  methods:{
    handleClick(){

    }
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  justify-content: space-between;
}
</style>
