<template>
  <div>
    <div style="display: flex;justify-content: space-between">
      <!--选择工点查看-->
      <div>
        <el-select style="margin-left: 5px;margin-bottom: 20px;width: 200px" v-model="opername" placeholder="请选择洞口"
                   size="small" @change="getflag">
          <el-option
              v-for="item in opernames"
              :key="item.flag"
              :label="item.operatingpoint"
              :value="item.flag"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-right: 100px">
        <el-date-picker
            v-model="mouth"
            type="month"
            format="YYYY/MM"
            value-format="YYYY-MM"
            placeholder="选择月份"
            size="small">
        </el-date-picker>
        <el-button type="warning" size="mini" @click="getall">查看汇总</el-button>
      </div>
    </div>
    <!--    数据表格-->
    <div v-if="one">
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="oneallData"
          size="mini"
          highlight-hover-row
          ref="xTable1"
          :export-config="{}"
      >
        <vxe-column field="mile" title="里程" width="200"></vxe-column>
        <vxe-column field="oppmile" title="长度" width="115"></vxe-column>
        <vxe-column field="stonelel" title="围岩级别" width="100"></vxe-column>
        <vxe-column field="a" title="部位" width="105">二衬</vxe-column>
        <vxe-column field="jztime" title="浇筑日期" width="120"></vxe-column>
        <vxe-column field="planfl" title="设计方量" width="100"></vxe-column>
        <vxe-column field="ylht" title="残余预留变形回填量" width="100"></vxe-column>
        <vxe-column field="realfl" title="实际方量" width="100"></vxe-column>
        <vxe-column field="jcnum" title="节超量" width="135"></vxe-column>
        <vxe-column field="beizhu" title="备注" width="135"></vxe-column>
      </vxe-table>
      <!--    分页-->
      <div style="float: right;margin-top: 20px;">
        <el-pagination v-model:currentPage="pagenum"
                       :small="true"
                       background layout="prev, pager, next" :total="totals">
        </el-pagination>
      </div>
    </div>
    <div v-if="!one">
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="allData"
          size="mini"
          highlight-hover-row
          ref="xTable1"
          :export-config="{}"
      >
        <vxe-column field="bdstname" title="工区" width="200"></vxe-column>
        <vxe-column field="opername" title="工点" width="115"></vxe-column>
        <vxe-column field="mile" title="二衬端头里程" width="100"></vxe-column>
        <vxe-colgroup title="当月二衬统计">
          <vxe-column field="oppmile" title="浇筑长度(m)" width="90"></vxe-column>
          <vxe-column field="planfl" title="设计浇筑砼量" width="90"></vxe-column>
          <vxe-column field="realfl" title="实际浇筑砼量" width="70"></vxe-column>
          <vxe-column field="morenum" title="二衬砼超耗率(%)" width="70"></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup title="累计二衬统计">
          <vxe-column field="alloppmile" title="累计长度(m)" width="90"></vxe-column>
          <vxe-column field="allplanfl" title="设计浇筑砼量" width="90"></vxe-column>
          <vxe-column field="allrealfl" title="实际浇筑砼量" width="70"></vxe-column>
          <vxe-column field="allmorenum" title="二衬砼超耗率(%)" width="70"></vxe-column>
        </vxe-colgroup>
        <vxe-column field="beizhu" title="备注"></vxe-column>
      </vxe-table>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import {mapGetters} from "vuex";
export default {
  name: "backlin",
  data(){
    return{
      opername: '',
      opernames: [],
      mouth: '',
      one: true,
      allAlign: 'center',
      pagenum: 1,
      totals: 0,
      oneallData: [],
      allData: []
    }
  },
  created() {
    console.log(this.GET_BDST)
    this.axios.post('/sink/getoper', (response) => {
      response.obj.forEach((item, i ) =>{
        item.forEach((item1, y) => {
          this.opernames.push(item1)
        })
      });
    },{
      bdst: this.GET_BDST
    })
  },
  computed: {
    ...mapGetters(['GET_BDST'])
  },
  methods:{
    getflag(val){
      this.mouth = '';
      this.one = true;
      this.operid = val;
      this.axios.post('/sink/getopername', (response) =>{
        this.opername1 = response.obj.operatingpoint;
        this.getone();
      },{
        operid: val
      })
    },
    getone(){
      this.axios.get('/liningmain/getoneall', (response) => {
        this.totals = response.obj.pages * 10;
        this.oneallData = response.obj.records;
      },{
        flag: this.opername,
        Pagecount: 1,
        body: '1'
      })
    },
    getall(){
      if (this.mouth === ''){
        ElMessage.error("请选择月份")
      }else {
        this.opername = '';
        this.one = false;
        this.axios.get('/liningmain/getall', (response) => {
          this.allData = response.obj;
        },{
          month: this.mouth,
          bdst: this.GET_BDST,
          body: '1'
        })
      }
    },
    dellin(row){
      this.axios.post('/liningmain/dellin', (response) => {
        if (response.obj){
          this.getone();
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
        }
      },{
        id: row.id,
        body: '1'
      })
    }
  },
  watch: {
    pagenum(){
      this.axios.get('/liningmain/getoneall', (response) => {
        this.totals = response.obj.pages * 10;
        this.oneallData = response.obj.records;
      },{
        flag: this.opername,
        Pagecount: this.pagenum,
        body: '1'
      })
    }
  }
}
</script>

<style scoped>

</style>