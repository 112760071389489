<template>
  <div>
    <!--头部-->
    <div style="display: flex;justify-content: space-between">
      <!--选择工点查看-->
      <div>
        <el-select style="margin-left: 5px;margin-bottom: 20px;width: 200px" v-model="opername" placeholder="请选择洞口"
                   size="small" @change="getflag">
          <el-option
              v-for="item in opernames"
              :key="item.flag"
              :label="item.operatingpoint"
              :value="item.flag"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <!--表格-->
    <div>
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="tableData"
          size="mini"
          highlight-hover-row
          ref="xTable1"
          :export-config="{}"
      >
        <vxe-column field="mile" title="里程" width="200"></vxe-column>
        <vxe-column field="startime" title="预埋时间" width="115"></vxe-column>
        <vxe-column field="endtime" title="停测时间(观测日期)" width="120"></vxe-column>
        <vxe-column field="palnchange" title="设计预留变形量(mm)" width="150"></vxe-column>
        <vxe-column field="realchange" title="实际预留变形量(mm)" width="150"></vxe-column>
        <vxe-column field="realdown" title="实际下沉(mm)" width="120"></vxe-column>
        <vxe-column field="realsone" title="S1实际收敛(mm)" width="120"></vxe-column>
        <vxe-column field="realtwo" title="S2实际收敛(mm)" width="120"></vxe-column>
        <vxe-column field="beizhu" title="备注" width="110"></vxe-column>
        <vxe-column title="是否停测" width="100" show-overflow>
          <template #default="{ row }">
            <el-tag v-if="row.type === 1" type="danger" size="mini">已停测</el-tag>
            <el-tag v-if="row.type === 0" type="success" size="mini">未停测</el-tag>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ElMessage, ElMessageBox} from 'element-plus'
import {setNowDate} from "@/utils/time";
export default {
  inject: ['reload'],
  name: "backsink",
  data(){
    return{
      opernames: [],
      opername: '',
      allAlign: 'center',
      tableData: [],
      dialogAdd: false,
      dialogUpdata: false,
      miles: [],
      mile: '',
      milexx: '',
      operid: '',
      form: {
        opername: '',
        mile: '',
        startime: '',
        endtime: '',
        palnchange: '',
        realchange: '',
        realdown: '',
        realsone: '',
        realtwo: '',
        opernameid: '',
        opername1: '',
        beizhu: ''
      },
      form1: {
        id: '',
        opername: '',
        mile: '',
        startime: '',
        endtime: '',
        palnchange: '',
        realchange: '',
        realdown: '',
        realsone: '',
        realtwo: '',
        opernameid: '',
        opername1: '',
        beizhu: '',
        type: '0'
      }
    }
  },
  created() {
    this.axios.post('/sink/getoper', (response) => {
      response.obj.forEach((item, i ) =>{
        item.forEach((item1, y) => {
          this.opernames.push(item1)
        })
      });
    },{
      bdst: this.GET_BDST
    })
  },
  computed: {
    ...mapGetters(['GET_BDST']),
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID']),
  },
  methods:{
    getsink(){
      if (this.opername === ''){
        ElMessage({
          message: '请选择隧道',
          type: 'warning',
        })
      }else {
        this.axios.post("/sink/getallsink", (response) => {
          this.tableData = response.obj;
          console.log(this.tableData)
        },{
          operid: this.operid
        })
      }
    },
    getflag(val){
      this.operid = val;
      this.axios.post('/sink/getopername', (response) =>{
        this.opername1 = response.obj.operatingpoint;
        this.getsink();
      },{
        operid: val
      })
    },
  }
}
</script>

<style scoped>

</style>