<template>
  <div>
    <div style="margin-bottom: 10px;display: flex;justify-content: space-between">
      <div>
        <el-date-picker
            v-model="value2"
            type="date"
            placeholder="选择日期"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            style="margin-right: 50px"
            size="small"
            @change="changedate"
        >
        </el-date-picker>
        <el-button type="primary" size="small" @click="seleque">查询</el-button>
      </div>
      <div>
        <el-button type="danger" style="float: right;margin-right: 100px" size="small"><a style="text-decoration:none;color: white" :href="url1">导出</a></el-button>
      </div>
    </div>
    <div style="margin-bottom: 30px">
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="tableData"
          size="small"
          highlight-hover-row
          :span-method="mergeRowMethod"
          :cell-style="cellStyle"
          ref="xTable1"
          :export-config="{}"
      >
        <vxe-column field="operpotion" title="工点" width="180"></vxe-column>
        <vxe-column field="sum" title="循环时间" width="100"></vxe-column>
        <vxe-column field="milesum" title="循环进尺(榀/m)" width="70"></vxe-column>
        <vxe-column field="addup" title="累计进尺" width="70"></vxe-column>
        <vxe-colgroup title="工序用时">
          <vxe-column field="gxflkw" title="开挖" width="70"></vxe-column>
          <vxe-column field="gxflcz" title="出渣" width="70"></vxe-column>
          <vxe-column field="gxfllj" title="立架" width="70"></vxe-column>
          <vxe-column field="gxflpm" title="喷锚" width="70"></vxe-column>
          <vxe-column field="allsum" title="间隔" width="70"></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup title="间隔时间">
          <vxe-column field="allsum" title="间隔时间" width="70"></vxe-column>
          <vxe-column field="spacetime" title="原因" width="70"></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup title="投入要素">
          <vxe-column field="peonumkw" title="开挖" width="110"></vxe-column>
          <vxe-column field="peonumcz" title="出渣" width="110"></vxe-column>
          <vxe-column field="peonumlj" title="立架" width="110"></vxe-column>
          <vxe-column field="peonumpm" title="喷锚" width="110"></vxe-column>
        </vxe-colgroup>
        <vxe-column field="beizhu" title="备注" width="220"></vxe-column>
        <vxe-column title="操作" width="100" show-overflow>
          <template #default="{ row }">
            <vxe-button size="mini" status="primary" @click="getSelectionEvent(row)">查看明细</vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <!--    明细表-->
    <div>
      <vxe-modal v-model="mx" width="1000" height="650" :title="popname" show-zoom resize remember>
        <template #default>
          <div style="margin-bottom: 10px">
            <el-tag style="margin-right: 5px">填报人</el-tag>
            <el-tag>{{ username }}</el-tag>
          </div>
          <vxe-table
              show-overflow
              auto-resize
              height="350"
              :data="tableMx.collMxes">
            <vxe-column field="sgdate" title="施工日期"></vxe-column>
            <vxe-column field="gxname" title="工序"></vxe-column>
            <vxe-column field="starttime" title="开始"></vxe-column>
            <vxe-column field="endtime" title="结束"></vxe-column>
            <vxe-column field="gxtime" title="工序时长(min)"></vxe-column>
            <vxe-column field="jgtime" title="间隔时间(min)"></vxe-column>
            <vxe-column field="except" title="情况说明"></vxe-column>
            <vxe-column field="pro" title="人员及设备投入"></vxe-column>
          </vxe-table>
          <div style="margin-top: 10px">
            <el-tag style="margin-right: 10px">循环进尺</el-tag>
            <el-tag style="margin-right: 80px">{{ mile }} m/ {{ pin }} 榀</el-tag>
            <el-tag size="medium" style="margin-right: 10px">月累</el-tag>
            <el-tag style="margin-right: 80px">{{ moutnmile }}m</el-tag>
            <el-tag size="medium" style="margin-right: 10px">累计进尺</el-tag>
            <el-tag style="margin-right: 80px">{{ milesum }}m</el-tag>
          </div>
          <div style="margin-top: 30px">
            <el-tag style="margin-right: 10px">掌子面里程</el-tag>
            <el-tag>{{ milexx }}</el-tag>
          </div>
          <div style="margin-top: 30px">
            <el-tag style="margin-right: 10px">本次开挖支护循环总时间</el-tag>
            <el-tag style="margin-right: 80px">{{ timesum }}</el-tag>
            <el-tag style="margin-right: 10px">本次开挖支护循环间隔总时间(min)</el-tag>
            <el-tag>{{ jgsum }}</el-tag>
          </div>
          <div v-for="(img, i) in imgs" style="display: flex;margin-top: 20px">
            <div>
              <img style="width: 150px;height: 150px" :src="img" alt="#" @click="getbig(img)"/>
            </div>
          </div>
          <div style="text-align: center;margin-top: 20px" v-if="leader">
            <vxe-button type="submit" status="warning" @click="bcakbb">退回</vxe-button>
            <vxe-button type="reset" @click="mx = false">返回</vxe-button>
          </div>
        </template>
      </vxe-modal>
    </div>
<!--    修改-->
    <div>
      <vxe-modal v-model="updata" :title="roname" width="600" height="400" show-zoom resize remember fullscreen>
        <template #default>
          <div style="margin-top: 30px">
            <el-tag style="margin-right: 5px" type="info" size="small">施工日期</el-tag>
            <el-date-picker
                v-model="sgtime"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
                :clearable="false"
            >
            </el-date-picker>
<!--            榀数-->
            <el-tag style="margin-bottom: 10px;margin-left: 20px" type="info">请选择榀数</el-tag>
            <el-select style="margin-left: 5px;" v-model="ps" placeholder="请选择榀数" size="small">
              <el-option
                  v-for="item in pss"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
<!--          断面方式，围岩等级-->
          <div style="margin-top: 30px">
            <!--            围岩等级-->
            <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择围岩等级</el-tag>
            <el-select style="margin-left: 5px;" v-model="rock" placeholder="请选择围岩等级" size="small">
              <el-option
                  v-for="item in rocks"
                  :key="item.level"
                  :label="item.level"
                  :value="item.level"
              >
              </el-option>
            </el-select>
            <!--            断面方式-->
            <el-tag style="margin-bottom: 10px;margin-left: 20px" type="info">请选择断面</el-tag>
            <el-select style="margin-left: 5px;" v-model="pro" placeholder="请选择断面" size="small">
              <el-option
                  v-for="item in pros"
                  :key="item.profile"
                  :label="item.profile"
                  :value="item.profile"
              >
              </el-option>
            </el-select>
          </div>
<!--          里程-->
          <div style="margin-bottom: 60px;margin-top: 30px">
            <el-radio-group v-model="milechange">
              <el-tag style="margin-right: 5px">填报里程：</el-tag>
              <el-tag style="margin-right: 30px">{{ zqmile }}</el-tag>
              <el-tag style="margin-right: 5px">是否要更改里程：</el-tag>
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group>
          </div>
          <div v-if="milechange === 1" style="margin-top: 10px">
            <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择施工里程</el-tag>
            <el-select style="margin-left: 5px;" v-model="mil" placeholder="请选择里程" size="small">
              <el-option
                  v-for="item in mils"
                  :key="item.id"
                  :label="item.mileage"
                  :value="item.mileage"
              >
              </el-option>
            </el-select>
            <el-input style="margin-left: 10px;width: 330px" v-model="milxx" placeholder="请输入具体里程 XXX-XXX" clearable size="small" @blur="getnum"/>
            <el-tag type="info">今日开挖/m</el-tag>
            <el-input style="margin-left: 5px;width: 100px" disabled v-model="milsum" placeholder="今日开挖" clearable size="small"/>
          </div>
<!--          工序时间-->
          <div style="display: flex;margin-bottom: 20px" v-for="(item, i) in this.type">
            <el-tag type="danger" style="margin-right: 10px">{{ item.timename }}</el-tag>
            <el-date-picker
                v-model="times[i]"
                type="datetimerange"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                size="small"
                style="margin-right: 80px"
                :clearable="false"
                @change="gettime(i)"
            >
            </el-date-picker>
            <el-tag>情况说明</el-tag>
            <el-input size="small" v-model="sms[i]" style="width: 350px" placeholder="单工序施工异常、工序转换间隔时间超出正常时间等" clearable />
            <el-input size="small" v-model="peos[i]" style="width: 250px;margin-left: 20px" placeholder="人员及设备投入情况" clearable />
          </div>
          <div style="text-align: center;margin-top: 50px">
            <el-popconfirm
                confirm-button-text="已完成"
                cancel-button-text="未完成"
                icon="el-icon-info"
                icon-color="red"
                title="本次循环是否完成？"
                @confirm="confirmEvent"
                @cancel="cancelEvent"
            >
              <template #reference>
                <el-button size="small">提交</el-button>
              </template>
            </el-popconfirm>
              <el-button size="small" @click="updata = false">返回</el-button>
          </div>
        </template>
      </vxe-modal>
    </div>
    <div>
      <el-dialog :fullscreen="true" title="图片详情" v-model = "dialogVisibleimg" :before-close="handleClose">
        <div v-for="img in imgsbig">
          <img :src="img"/>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleimg = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisibleimg = false">确 定</el-button>
      </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {minTime, setNowDate, setNowHou, setNowMonth, setNowTimes} from '@/utils/time';
import {mapGetters} from "vuex";
import {algorithm} from "@/utils/algorithm";
import {ElMessage} from "element-plus";
import {Decrypt} from "@/utils/secret";
export default {
  inject: ['reload'],
  name: "selsdgx",
  data(){
    return{
      tableData: [],
      allAlign: 'center',
      value2: '',
      mx: false,
      tableColumn: [],
      tableMx: [],
      popname: '',
      moutnmile: '',
      milesum: '',
      mile: '',
      milexx: '',
      jgsum: '',
      timesum: '',
      pin: '',
      uuid: '',
      updata: false,
      roname: '',
      sgtime: [], // 修改窗口的施工日期
      pss: [{
        value: 0,
        label: 0
      },{
        value: 1,
        label: 1
      },{
        value: 2,
        label: 2
      },{
        value: 3,
        label: 3
      },{
        value: 4,
        label: 4
      }],
      ps: '', //修改窗口的榀数
      pro: '', // 修改窗口的断面方式
      pros: [], // 修改窗口的断面方式
      milechange: 0,
      zqmile: '',
      mils: [],
      mil: '',
      milxx: '',
      milsum: '',
      type: [],
      starttime: [],
      endtime: [],
      times: [[]],
      sms: [],
      peos: [],
      rock: '',
      rocks: [], // 围岩等级
      over: '', //  超时
      beizhu: '', // 备注
      falg: '', //  隧道口排序
      milexz: '', // 里程详细，DK23-36.2
      milexzsum: '', //  修改后进尺
      timeflag: true, //  判断工序时间是否正确存入
      imgs: [],
      dialogVisibleimg: false,
      imgsbig: [],
      username: '',
      leader: false,
      finshed: 0,
      url1: ''
    }
  },
  created() {
    this.value2 = setNowDate(new Date());
    let date = setNowDate(new Date());
    this.gettable(date);
    this.url1 = 'https://www.gjegm.ltd/czsczq/other/leadout?date='+ setNowDate(new Date()) + '&bdst=' + this.GET_BDST + '&tj=1';
  },
  computed: {
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_BDST'])
  },
  methods:{
    changedate(){
      //this.url = 'https://www.gjegm.ltd/czsczq/other/leadout?date=' + this.value2;
      this.url = 'https://www.gjegm.ltd/czsczq/other/leadout?date=' + this.value2 + '&bdst=' + this.GET_BDST + '&tj=1';
    },

    confirmEvent() {
      this.finshed = 0;
      this.tjpdata();
    },
    cancelEvent() {
      this.finshed = 1;
      this.tjpdata();
    },
    handleClose(done) {
      this.dialogVisibleimg = false
    },
    getbig(img){
      this.imgsbig = [];
      this.imgsbig.push(img)
      console.log(this.imgsbig)
      this.dialogVisibleimg = true;
    },
    gettable(val){
      this.tableData = [];
      this.axios.post("/collectmain/seldate", (response) => {
        this.tableData = response.obj;
        console.log(response.obj)
        if (this.tableData.length === 0){
          this.addnum = 0;
        }else {
          this.addnum = 1;
          for (let i = 0; i < this.tableData.length - 1; i++) {
            if (this.tableData[i].operpotion !== this.tableData[i + 1].operpotion) {
              this.addnum = (this.addnum + 1) * 1
            }
          }
        }
      },{
        date: val,
        bdst: this.GET_BDST,
        tj: 1
      })
    },
    seleque(){
      this.gettable(this.value2);
    },
    mergeRowMethod ({ row, _rowIndex, column, visibleData }) {
      const fields = ['operpotion']
      const cellValue = row[column.property]
      if (cellValue && fields.includes(column.property)) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && prevRow[column.property] === cellValue) {
          return {rowspan: 0, colspan: 0}
        } else {
          let countRowspan = 1
          while (nextRow && nextRow[column.property] === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return {rowspan: countRowspan, colspan: 1}
          }
        }
      }
    },
    getSelectionEvent (row) {
      console.log(row)
        this.uuid = row.uuid;
        this.mile = row.milesum;
        this.popname = row.operpotion;
        this.moutnmile = row.mothsum;
        this.milesum = row.addup;
        this.timesum = row.sum;
        this.jgsum = row.allsum;
        this.mx = true;
        this.axios.post("/collectmain/getmx", (response) => {
          this.tableMx = response.obj;
          this.milexx = response.obj.collectmain.mile;
          this.pin = response.obj.collectmain.pinshu;
          this.username = response.obj.user;
          for (let i = 0; i < this.tableMx.collMxes.length; i++){
            if (this.tableMx.collMxes[i].pro === 'undefined'){
              this.tableMx.collMxes[i].pro = ' '
            }
            if (this.tableMx.collMxes[i].except === 'undefined'){
              this.tableMx.collMxes[i].except = ' '
            }
          }
          this.axios.post("other/selimgs", (responseimg) => {
            this.imgs = responseimg.obj
          },{
            id: row.uuid
          })
          this.axios.post("/userinfo/leader", (responseleader) => {
            console.log(responseleader)
            this.leader = responseleader.obj
          },{
            id: this.GET_USERID
          })
        },{
          id: row.id
        })
    },
    gettime(i){
      this.starttime[i] = this.times[i][0];
      this.endtime[i] = this.times[i][1];
    },
    updataback(row){
      this.sgtime = [];
      this.axios.get('/other/getallrock', (response) =>  {
        this.rocks = response.obj
      })
      this.axios.get('/other/getallpro', (response) =>  {
        this.pros = response.obj
      })
      this.axios.post("/other/getmilebyname", (response) => {
        this.mils = response.obj
      },{
        name: row.operpotion
      })
      this.axios.post("/other/getime", (response) => {
        this.type =  response.obj
      },{
        status: 0
      })
      this.updata = true;
      console.log(row)
      this.roname = row.operpotion;
      this.uuid = row.uuid;
      this.axios.post("/other/getcollone", (response) => {
        console.log(response)
        this.falg = response.obj.collectmain.flag;
        this.milexz = response.obj.collectmain.mile;
        this.milexzsum = response.obj.collectmain.milesum;
        let arr = response.obj.collectmain.datatime.split(",");
        this.sgtime.push(arr[0]);
        this.sgtime.push(arr[1]);
        this.ps = response.obj.collectmain.pinshu;
        this.rock = response.obj.collectmain.level;
        this.pro = response.obj.collectmain.daunmian;
        this.zqmile = response.obj.collectmain.mile;
        for (let i = 0; i < response.obj.colltimes.length; i++){
          if (response.obj.colltimes[i].timename === '开挖'){
            if (response.obj.colltimes[i].except !== 'undefined'){
              this.sms[i] = response.obj.colltimes[i].except;
            }
            if (response.obj.colltimes[i].peonum !== 'undefined'){
              this.peos[i] = response.obj.colltimes[i].peonum;
            }
          }
          if (response.obj.colltimes[i].timename === '出渣'){
            if (response.obj.colltimes[i].except !== 'undefined'){
              this.sms[i] = response.obj.colltimes[i].except;
            }
            if (response.obj.colltimes[i].peonum !== 'undefined'){
              this.peos[i] = response.obj.colltimes[i].peonum;
            }
          }
          if (response.obj.colltimes[i].timename === '初喷'){
            if (response.obj.colltimes[i].except !== 'undefined'){
              this.sms[i] = response.obj.colltimes[i].except;
            }
            if (response.obj.colltimes[i].peonum !== 'undefined'){
              this.peos[i] = response.obj.colltimes[i].peonum;
            }
          }
          if (response.obj.colltimes[i].timename === '立架(挂网)'){
            if (response.obj.colltimes[i].except !== 'undefined'){
              this.sms[i] = response.obj.colltimes[i].except;
            }
            if (response.obj.colltimes[i].peonum !== 'undefined'){
              this.peos[i] = response.obj.colltimes[i].peonum;
            }
          }
          if (response.obj.colltimes[i].timename === '喷锚'){
            if (response.obj.colltimes[i].except !== 'undefined'){
              this.sms[i] = response.obj.colltimes[i].except;
            }
            if (response.obj.colltimes[i].peonum !== 'undefined'){
              this.peos[i]= response.obj.colltimes[i].peonum;
            }
          }
          if (response.obj.colltimes[i].starttime){
            this.times[i][0] = new Date(response.obj.colltimes[i].starttime);
            this.times[i][1] = new Date(response.obj.colltimes[i].endtime);
            this.times.push([]);
            this.gettime(i);
          }
        }
      },{
        id: row.uuid
      })
    },
    getnum(){
      if (this.milxx !== ''){
        let arr = this.milxx.split("-");
        if (arr.length === 1){
          this.milsum = ''
        }else {
          if (parseInt(arr[0]) > parseInt(arr[1])){
            this.milsum = algorithm(arr[0], -arr[1])
          }else {
            this.milsum = algorithm(arr[1], -arr[0])
          }
        }
      }else {
        this.milsum = ''
      }
    },
    tjpdata(){
      const that = this;
      let month = setNowMonth();
      let date = setNowDate(new Date());
      if (setNowHou(new Date()) < 11){
        this.over = 0;
      }
      if (setNowHou(new Date()) >= 11){
        this.over = 1;
      }
      let peonums = '';
      for (let i = 0 ; i < this.peos.length; i++){
        if (this.peos[i]){
          if (i === 0){
            peonums = peonums + this.peos[i]
          }else {
            peonums = peonums + ';' + this.peos[i]
          }
        }
      }
      for (let i = 0; i < this.sms.length; i++){
        if (this.sms[i]){
          if (this.beizhu === ''){
            this.beizhu = this.beizhu + this.sms[i]
          }else {
            this.beizhu = this.beizhu + ';' + this.sms[i]
          }
        }
      }
      if (this.milechange === 1){
        console.log(this.milexz)
        console.log(this.milexzsum)
        if (!this.milexz || !this.milexzsum){
          ElMessage({
            message: '请填写更改后的里程数据',
            type: 'warning',
          })
          return;
        }else {
          this.milexz = this.mil + this.milxx;
          this.milexzsum = this.milsum;
        }
      }
      this.axios.post("/other/updatacollect", (response) => {
        if (response.obj) {
          that.axios.post("/other/removect", (response1) => {
            if (response1.obj){
              for (let  i = 0; i < this.type.length; i++) {
                if (this.starttime[i]) {
                  this.axios.post("/colltime/savecolltim", (response2) => {
                    this.timeflag = response2.obj;
                  }, {
                    timename: this.type[i].timename,
                    starttime: setNowTimes(this.starttime[i]),
                    collectid: this.uuid,
                    endtime: setNowTimes(this.endtime[i]),
                    flag: i,
                    except: this.sms[i],
                    peonum: this.peos[i]
                  })
                }
                if (i === this.type.length - 1 && this.over !== ''){
                  this.axios.post("/other/adduserup", (response) => {
                    if (response.obj){
                      this.reload();
                      this.updata = false;
                      ElMessage({
                        message: '修改成功！',
                        type: 'success',
                      })
                    }
                  },{
                    username: this.GET_USER,
                    proname: this.roname,
                    time: setNowTimes(new Date()),
                    formname: '隧道工效',
                    overtime: this.over,
                    flag: this.falg,
                    userid: this.GET_USERID,
                    collectid: this.uuid,
                    back: 1
                  })
                }
              }
            }
          },{
            id: this.uuid
          })
        }
      },{
        id: this.uuid,
        datatime: this.sgtime,
        mile: this.milexz,
        pinshu: this.ps,
        level: this.rock,
        daunmian: this.pro,
        operpotion: this.roname,
        milesum: this.milexzsum,
        peonum: peonums,
        beizhu: this.beizhu,
        moth: month,
        adddate: date,
        flag: this.falg,
        th: 0,
        isend: this.finshed
      })
    },
    bcakbb(){
      const that = this;
      that.axios.post("/other/getuserid", (response1) =>{
        if (response1.obj){
          that.axios.post("/userinfo/gettel", (response2) => {
            let tel = response2.obj;
            let reltel = Decrypt(tel);
            that.axios.post("https://www.cr12cz.cn/czscsms/sms/sendsms", (response3) => {
              if (response3.message === '验证码发送成功！') {
                that.axios.post("/collectmain/backsgdx", (response) => {
                  if (response.obj) {
                    that.reload();
                    that.mx = false;
                    ElMessage.success({
                      message: '退回成功',
                      type: 'success',
                    });
                  }
                }, {
                  id: that.uuid
                })
              }
            },{
              templateidsgin: '1163874,高佳的奇思妙想',
              phone: reltel,
              mes: '隧道工序报表'
            })
          },{
            id: response1.obj
          })
        }
      },{
        id: that.uuid
      })
    }
  }
}
</script>

<style scoped>
</style>
