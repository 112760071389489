// 格式化小数
export function algorithm(arg1, arg2) {

  arg1 = arg1+'';
  arg2 = arg2+'';
  let arg1Arr = arg1.split("."); // 将小数的数据从小数点的位置拆开
  let arg2Arr = arg2.split(".");
  let d1 = arg1Arr.length === 2 ? arg1Arr[1] : ""; // 获取第一个数的小数点的长度
  let d2 = arg2Arr.length === 2 ? arg2Arr[1] : ""; // 获取第二个数的小数点的长度
  let maxLen = Math.max(d1.length, d2.length); // 获取小数点长度较大的值
  let m = Math.pow(10, maxLen); // 这里表示10的小数点长度次方 也就是说如果小数点长度为2 m的值就是100 如果小数点长度是3 m的值就是1000如果不懂请自行查找api
  let result = Number(((arg1 * m + arg2 * m) / m).toFixed(maxLen)); // 将小数转化为整数后相加在除掉两个数乘过的倍数然后去小数点较长的长度的小数位数
  let d = arguments[2]; // 第三个参数用户可以自行决定是否要传递 用来定义要保留的小数长度
  return typeof d === "number" ? Number((result).toFixed(d)) : result;
}
