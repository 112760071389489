<template>
  <div>
    <div style="margin-bottom: 10px;display: flex;justify-content: space-between">
      <div>
        <el-date-picker
            v-model="backshortime"
            type="date"
            placeholder="选择日期"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            style="margin-right: 50px"
            size="small"
        >
        </el-date-picker>
        <el-select v-if="pd" style="margin-right: 45px;" v-model="userbdst" placeholder="请选择要查询的工区" size="small" @change="getcuts">
          <el-option
              v-for="item in bdsts"
              :key="item.bdstname"
              :label="item.bdstname"
              :value="item.bdst"
          >
          </el-option>
        </el-select>
        <el-button type="primary" size="small" @click="selbeton">查询</el-button>
      </div>
    </div>
<!--    查询列表-->
    <div>
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="tableData"
          size="mini"
          highlight-hover-row
          ref="xTable1"
          :export-config="{}"
      >
        <vxe-column field="bdstname" title="工区" width="100"></vxe-column>
        <vxe-column field="opername" title="工点" width="115"></vxe-column>
        <vxe-column field="mile" title="里程" width="120"></vxe-column>
        <vxe-column field="m" title="部位" width="80">
          <template #default="{ row }">
            <p v-if="row.orderflag === 1">全断面</p>
            <p v-if="row.orderflag === 2">上台阶</p>
            <p v-if="row.orderflag === 3">中台阶</p>
            <p v-if="row.orderflag === 4">下台阶</p>
            <p v-if="row.orderflag === 5">仰供支护</p>
          </template>
        </vxe-column>
        <vxe-column field="oppmile" title="相对进尺长度(m)" width="70"></vxe-column>
        <vxe-column field="planps" title="设计喷栓量" width="70"></vxe-column>
        <vxe-column field="realps" title="实际喷栓量" width="80"></vxe-column>
        <vxe-column field="consu" title="应耗量" width="80"></vxe-column>
        <vxe-column field="morenum" title="超耗" width="80"></vxe-column>
        <vxe-column field="more" title="喷栓超耗率(%)" width="80"></vxe-column>
        <vxe-column field="maxnum" title="最大线性超挖" width="80"></vxe-column>
        <vxe-column field="meanum" title="平均线性超挖" width="80"></vxe-column>
        <vxe-column field="beizhu" title="备注" width="100"></vxe-column>
        <vxe-column title="操作" width="100" show-overflow>
          <template #default="{ row }">
            <vxe-button size="mini" status="primary" @click="getSelectionEvent(row)">查看明细</vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <!--    明细弹窗-->
    <div>
      <vxe-modal v-model="mx" width="1500" height="650" title="查看明细" show-zoom resize remember>
        <template #default>
          <vxe-table
              show-overflow
              auto-resize
              height="350"
              :span-method="mergeRowMethod"
              :align="allAlign"
              :data="tableMx">

            <vxe-column field="mile" width="200" title="里程"></vxe-column>
            <vxe-column field="m" title="部位" width="80">
              <template #default="{ row }">
                <p v-if="row.orderflag === 1">全断面</p>
                <p v-if="row.orderflag === 2">上台阶</p>
                <p v-if="row.orderflag === 3">中台阶</p>
                <p v-if="row.orderflag === 4">下台阶</p>
                <p v-if="row.orderflag === 5">仰供支护</p>
              </template>
            </vxe-column>
            <vxe-column field="oppmile" title="相对进尺长度"></vxe-column>
            <vxe-column field="planum" title="设计量(m³)"></vxe-column>
            <vxe-column field="realnum" title="实际量(m³)"></vxe-column>
            <vxe-column field="consu" title="应耗量(m³)"></vxe-column>
            <vxe-column field="morenum" title="超耗(m³)"></vxe-column>
            <vxe-column field="more" title="超耗率(%)"></vxe-column>
            <vxe-column field="maxnum" title="最大线性超挖(cm)"></vxe-column>
            <vxe-column field="meanum" title="平均线性超挖(cm)"></vxe-column>
            <vxe-column field="beizhu" title="备注"></vxe-column>
            <vxe-column v-if="GET_POSION === '部长'" field="betonid" width="200" title="操作">
              <template #default="{ row }">
                <el-button size="mini" type="danger" @click="delbeton(row)">删除</el-button>
              </template>
            </vxe-column>
          </vxe-table>
        </template>
      </vxe-modal>
    </div>
  </div>
</template>

<script>
import {setNowDate} from "@/utils/time";
import {mapGetters} from "vuex";
import { ElMessage } from 'element-plus'
export default {
  name: "backfirstshort",
  data(){
    return{
      backshortime: '',
      bdsts: [],
      userbdst: '',
      pd: false,
      allAlign: 'center',
      tableData: [],
      mx: false,
      tableMx: []
    }
  },
  created() {
    this.backshortime = setNowDate(new Date());
    if (this.GET_BDST.length > 2){
      this.pd = true;
      this.axios.post('/czbeton/pd', (response) => {
        this.bdsts = response.obj;
      },{
        bdst: this.GET_BDST
      })
    }else {
      this.userbdst = this.GET_BDST
    }

  },
  computed: {
    ...mapGetters(['GET_BDST']),
    ...mapGetters(['GET_POSION'])
  },
  methods:{
    selbeton(){
      if (this.userbdst === ''){
        ElMessage.error('请选择要查询的工区！')
      }else {
        this.axios.post('/czbeton/getallbeton', (response) => {
          this.tableData = response.obj;
        },{
          time: this.backshortime,
          bdst: this.userbdst
        })
      }

    },
    getSelectionEvent(row){
      this.opername = row.opername;
      this.mx = true;
      this.axios.post('/czbeton/getmxbeton', (response) => {
        console.log(response.obj)
        this.tableMx = response.obj;
      },{
        opername: row.opername,
        time: this.backshortime,
      })
    },
    mergeRowMethod ({ row, _rowIndex, column, visibleData }) {
      const fields = ['betonid']
      const cellValue = row[column.property]
      if (cellValue && fields.includes(column.property)) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && prevRow[column.property] === cellValue) {
          return {rowspan: 0, colspan: 0}
        } else {
          let countRowspan = 1
          while (nextRow && nextRow[column.property] === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return {rowspan: countRowspan, colspan: 1}
          }
        }
      }
    },
    delbeton(row) {
      this.axios.post('/czbeton/delbeton', (response) => {
        if (response.obj) {
          this.reload();
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
        }
      }, {
        id: row.betonid
      })
    },
  }
}
</script>

<style scoped>

</style>